import React from "react"

export const GlobalStateContext = React.createContext()
export const GlobalDispatchContext = React.createContext()

const initialState = {
  scrollToSection: '',
  closeSwitcher: false,
  darkModePreference: 'sun',
  previousUrl:"",
  lastVisitedPage: "",
  svgUrl: '', 
  hasSwitcherAnimation: true,
  siteNav: {
    isOpen: false,
  },
  siteSubNav: {

  },
  cookieConsent: {
    isOpen: true,
  },
  language: "",
   isPageTransition: {
    isPageTransitionActive: false, 
    color: ""
  } , 
  darkMode: false,
}

function reducer(state, action) {
  switch (action.type) {
    case 'SET_PREVIOUS_URL':
      return {
        ...state,
        previousUrl: action.payload,
      };
    case 'SET_SVG_URL':
      return {
        ...state,
        svgUrl: action.payload,
      };
    case 'SET_LAST_VISITED_PAGE':
      return {
        ...state,
        lastVisitedPage: action.payload,
      };
    case 'TURN_SWITCHER_ANIMATION':
      return {
        ...state,
        hasSwitcherAnimation: true,
      };
    case 'OFF_SWITCHER_ANIMATION':
      return {
        ...state,
        hasSwitcherAnimation: false,
    };
    case 'TOGGLE_DARK_MODE':
      return {
        ...state,
        darkMode: !state.darkMode,
      };
    case 'SHOW_HOMEPAGE_DARK_MODE':
      return {
        ...state,
        darkModePreference: "moon",
      };
    case 'HIDE_HOMEPAGE_DARK_MODE':
      return {
        ...state,
        darkModePreference: "sun",
      };
    case 'SHOW_DARK_MODE':
      return {
        ...state,
        darkMode: true,
      };
    case 'SET_SCROLL_TO_SECTION': {
      return {
        ...state,
        scrollToSection: action.payload.id
      }
    }
    case 'CLOSE_SWITCHER':
      if(action.payload) {
        return {
          ...state,
          closeSwitcher: true,
        };
      } else  {
        return {
          ...state,
          closeSwitcher: false,
       };
      };
    case 'RESET_SCROLL_TO_SECTION': {
      return {
        ...state,
         scrollToSection: ""
      }
    }
    case 'TOGGLE_SITE_NAV': {
      return {
        ...state,
        siteNav: {
          isOpen: !state.siteNav.isOpen,
        },
      }
    }
    
    case "RESET_SITE_SUB_NAV": {
      return {
        ...state,
        siteSubNav: {

        },
      }
    }
    case "TOGGLE_SITE_SUB_NAV": {
      return {
        ...state,
        siteSubNav: {
         [action.payload.id]: !state.siteSubNav[action.payload.id],
        },
      }
    }
    case "CLOSE_SITE_NAV": {
      return {
        ...state,
        siteNav: {
          isOpen: false,
        },
      }
    }
    case "SET_LANGUAGE": {
      return {
        ...state,
        language: action.language,
      }
    }
    case "CLOSE_COOKIE_CONSENT": {
      return {
        ...state,
        cookieConsent: {
          isOpen: false,
        },
      }
    }
    case "TOGGLE_SITE_PAGE_TRANSITION": {
      return {
        ...state,
        isPageTransition: {
          ...state.isPageTransition, // Spread the existing properties
          isPageTransitionActive: !state.isPageTransition.isPageTransitionActive,
          color: action.payload || ''
        } 
      }
    }
    default:
      throw new Error("Bad Action Type")
  }
}

const GlobalContextProvider = ({ children }) => {
  const [state, dispatch] = React.useReducer(reducer, initialState)
  return (
    <GlobalStateContext.Provider value={state}>
      <GlobalDispatchContext.Provider value={dispatch}>
        {children}
      </GlobalDispatchContext.Provider>
    </GlobalStateContext.Provider>
  )
}

export default GlobalContextProvider
